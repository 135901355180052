import React from "react";
import { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./media-content.css";
import Colostrum from "./slides/Colostrum";
import Prebiotics from "./slides/Prebiotics";
import Probiotics from "./slides/Probiotics";
import Postbiotics from "./slides/Postbiotics";
import GutBrainAxis from "./slides/GutBrainAxis";

function Media(slide) {
  const {
    topicType,
    // eslint-disable-next-line
    setTopicType,
    topicChosen,
    // eslint-disable-next-line
    setTopicChosen,
  } = useContext(TopicContext);

  return topicChosen && topicType === "gut-brain-axis" ? (
    <GutBrainAxis {...slide} />
  ) : topicChosen && topicType === "probiotics" ? (
    <Probiotics {...slide} />
  ) : topicChosen && topicType === "prebiotics" ? (
    <Prebiotics {...slide} />
  ) : topicChosen && topicType === "postbiotics" ? (
    <Postbiotics {...slide} />
  ) : topicChosen && topicType === "colostrum" ? (
    <Colostrum {...slide} />
  ) : (
    <div id="central-media-container">
      <div className="central-media-content">
        <h2>
          Something Went Wrong
          <hr />
        </h2>
      </div>
    </div>
  );
}
export default Media;
