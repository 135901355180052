import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./nav-bar.css";

export const NavBar = () => {
  // eslint-disable-next-line
  const { topicType, setTopicType } = useContext(TopicContext);
  const navBarTopics = Array.from(
    document.getElementsByClassName("navigation-link"),
  );
  const handleClick = (e) => {
    setTopicType((topic) => e.target.id);

    navBarTopics.forEach((el) => el.classList.remove("selected"));
    e.target.classList.add("selected");
  };
  const initializeTopic = navBarTopics.forEach((el) => {
    el.id === topicType
      ? el.classList.add("selected")
      : el.classList.remove("selected");
  });

  const dropdownClick = (e) => {
    e.target.classList.toggle("open");
  };

  return (
    initializeTopic,
    (
      <header className="app-header">
        <div className="nav-link-container">
          <div
            onClick={handleClick}
            id="gut-brain-axis"
            className="navigation-link"
          >
            Gut-Brain Axis
            <div className="lozenge"></div>
          </div>
          <div
            onClick={handleClick}
            id="probiotics"
            className="navigation-link"
          >
            Probiotics
            <div className="lozenge"></div>
          </div>
          <div
            onClick={handleClick}
            id="prebiotics"
            className="navigation-link"
          >
            Prebiotics
            <div className="lozenge"></div>
          </div>
          <div
            onClick={handleClick}
            id="postbiotics"
            className="navigation-link"
          >
            Postbiotics
            <div className="lozenge"></div>
          </div>
          <div onClick={handleClick} id="colostrum" className="navigation-link">
            Colostrum
            <div className="lozenge"></div>
          </div>
          <div className="dropdown" onClick={dropdownClick}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Selected%20Info%20Icon.png"
              alt="Learn More"
            />
            <div className="dropdown-content">
              <div>
                <a
                  id="learn-more"
                  href="https://www.purinainstitute.com/science-of-nutrition/promoting-gastrointestinal-health"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Learn More</p>
                </a>
              </div>

              <div id="references">
                <a
                  href="https://www.purinainstitute.com/promoting-gastrointestinal-health/references-list"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>References</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </header>
    )
  );
};
